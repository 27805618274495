.Two-Factor-title {
  font-weight: 700;
  font-size: 20px;
  margin: 5px;
}
.Two-Factor-Qr-title {
  margin: 20px;
  font-weight: 500;
  font-size: 1.2rem;
}

.Two-Factor-Paper {
  text-align: center;
}
.qrCode {
  margin: 20px 0;
}
.mLink {
  margin: 10px;
}
.back-icon-auth {
  margin: 0 10px;
  font-size: 2rem;
  font-weight: 900;
}
