.header-main {
  padding: 10px;
  position: sticky;
  top: 0;
  background: #263164;
  z-index: 1;
}
.imgHeader {
  width: 135px;
}
.header-user-name {
  color: #fff;
  text-transform: uppercase;
  padding: 0 5px;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 0.8;
}
.header-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  float: right;
  bottom: 0;
}
.rotateImage {
  transform: rotate(3.142rad);
}
.header-list li {
  display: inline-block;
  margin: 0 5px;
  padding: 5px;
  background: #1A2454;
  border-radius: 4px;
}
.profile {
  display: grid;
  padding: 5px;
  grid-template-columns: auto auto auto;
}
.list-icon {
  font-size: 16px;
  color: #fff;
}

.list-icon-user {
  font-size: 13px;
  color: #fff;
}
.icon-arrow {
  font-size: 1rem;
  font-weight: 900 !important;
  line-height: 0.6 !important;
}

.profile-menu {
  opacity: 1;
  color: #000;
  transform: none;
  right: 10px;
  top: 55px;
  transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform-origin: 60px 0px;
  position: absolute;
  background-color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  overflow: hidden auto;
  min-width: 280px;
  min-height: 56px;
  max-width: calc(100vh - 32px);
  max-height: calc(100vh - 32px);
  outline: 0px;
  z-index: 1;
  /* padding: 10px; */
}
