.submit-btn {
  /* float: right; */
  width:fit-content;
}

.forgot-password-title {
  margin-right: 10px;
  font-weight: 700;
  font-size: 2rem;
}
