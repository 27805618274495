.snackbar {
  padding: 15px;
  min-width: 20%;
  max-width: 50%;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.bottom-center {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  display: flex;
  width: 96%;
  justify-content: center;
  margin: 2%;
}

.success {
  background-color: #2e7d32;
  color: white;
}

.error {
  background-color: #d32f2f;
  color: white;
}
.information {
  background-color: #0288d1;
  color: white;
}

.warning {
  background-color: #ed6c02;
  color: white;
}

.close{
  float: right;
  font-weight: 200;
  margin-top: 4px;
  cursor: pointer;
}