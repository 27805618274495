body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #263164;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.chart {
  margin: 10px 0px 20px 0px;
  width: 100%;
  padding:0 20px 20px;
  max-height: 380px !important;
  min-height: 250px !important;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #9fb6c3;
  -webkit-box-shadow: none;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #e3ebef;
}
::-webkit-scrollbar {
  width: 3px;
}
