.notification-menu {
  opacity: 1;
  color: #000;
  transform: none;
  right: 10px;
  top: 55px;
  transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform-origin: 60px 0px;
  position: absolute;
  background-color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  overflow: hidden auto;
  min-width: 380px;
  min-height: 56px;
  max-width: calc(100vh - 32px);
  max-height: calc(100vh - 32px);
  outline: 0px;
  z-index: 1;
  /* padding: 10px; */
}
.notification-redirect{
  cursor: pointer;
  text-decoration-line:underline;
  color: blue;
  font-size: 14px;
}
.noNotification {
  margin: 100px auto;
  text-align: center;
}
.notification-card-bar {
  display: grid;
  grid-template-columns: 7% 60% 33%;
  font-size: 14px;
  font-weight: 400;
}
.notification-card {
  transition: all 500ms ease;
  background: #e9f0f3;
  padding: 15px;
  border: 0px;
  border-style: solid;
  border-bottom-width: 1px;
  -moz-border-image: -moz-linear-gradient(right, white, #cedae0, #cedae0, white)
    1 20%;
  -o-border-image: -o-linear-gradient(right, white, #cedae0, #cedae0, white) 1
    20%;
  border-image: linear-gradient(
      to right,
      white 0%,
      #cedae0 40%,
      #cedae0 60%,
      white 100%
    )
    1 20%;
}
.unread {
  background: white;
}
.notification-title {
  word-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
}
.notification-header {
  padding: 10px;
  position: relative;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border: 0px;
  border-style: solid;
  border-bottom-width: 1px;
  background: #fff;
  padding: 15px;
  border-image: linear-gradient(
      to right,
      white 0%,
      #cedae0 40%,
      #cedae0 60%,
      white 100%
    )
    1 20%;
  box-shadow: 0px 2px 10px -2px #cedae0;
}

.markAllAsRead {
  font-size: 14px;
  font-weight: 400;
  float: right;
  line-height: 2;
  cursor: pointer;
}
.notification-content {
  max-height: 292px;
  background: #e9f0f3;
  overflow-y: auto;
  overflow-x: hidden;
}
.triangle {
  position: absolute;
  top: 85px;
  height: 15px;
  width: 15px;
  border-radius: 6px 0px 0px 0px;
  transform: rotate(45deg);
  background: #fff;
}

.profile-card {
  display: grid;
  grid-template-columns: 15% 85%;
  background: #fff;
  font-size: 1rem;
}
.profile-card:hover {
  background: #e9f0f3;
}
.profile-card img {
  width: 20px;
  height: 20px;
}
.time-text {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.notification-message {
  font-size: 14px;
  margin: 4px 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
#notification-bell {
  position: relative;
}
.batch {
  position: absolute;
  top: -10px;
  animation: beamer_bounce 1.5s linear infinite;
  border-radius: 50%;
  width: 18px;
  right: -13px;
  z-index: 1;
  font-weight: bold;
  font-size: 10px;
  padding: 1.5px;
  text-align: center;
  background-color: red;
  color: white;
}

.rotate-icon {
  transform: rotate(45deg);
  display: block;
}
