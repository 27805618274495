.header {
  background: #263164;
  padding: 10px;
  position: sticky;
  top: 0;
}
.greeting {
  height: 29px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  margin: 10px 20px 20px;
  margin-left: 80px;
  color: #ffffff;
}

.backdrop-filter{
  width:100vw;
  height:100vh;
  position:fixed;
  background-color:rgba(0, 0, 0, 0.5) ;
  /* filter:blur(4px); */
  z-index:10
}

.service-left {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.service-right {
  position: fixed;
  overflow-y: scroll;
  max-height: 100%;
  width: 36%;
  padding: 0 4%;
  overflow: auto;
  right: 0;
  bottom: 0;
  top: 125px;
}
.organization-text {
  padding: 0 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}
.iframe {
  width: 60%;
  height: 70vh;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
}

.iframe-main {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100%;
}

@media (max-width: 1293px) {
  .service-left {
    width: 100%;
    margin-bottom: 10px;
  }
  .service-right {
    width: 90%;
    position: relative;
    padding: 0 5%;
    top: 0;
  }
  .gauge-scale{
    left:30%;
    top:9.5rem
  }
}
@media (max-width: 768px) {
}
