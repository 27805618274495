@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ha5xrh');
  src:  url('fonts/icomoon.eot?ha5xrh#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ha5xrh') format('truetype'),
    url('fonts/icomoon.woff?ha5xrh') format('woff'),
    url('fonts/icomoon.svg?ha5xrh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-smiley:before {
  content: "\e908";
}
.icon-speed:before {
  content: "\e90d";
}
.icon-keyboard_arrow_left:before {
  content: "\e909";
}
.icon-cloud_done:before {
  content: "\e906";
}
.icon-help_outline:before {
  content: "\e904";
}
.icon-help:before {
  content: "\e905";
}
.icon-home_repair_service:before {
  content: "\e902";
}
.icon-home_work:before {
  content: "\e903";
}
.icon-clear:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e900";
}
.icon-logout:before {
  content: "\e901";
}
.icon-live_help:before {
  content: "\e95f";
}
.icon-keyboard_arrow_up:before {
  content: "\e956";
}
.icon-keyboard_arrow_down:before {
  content: "\e957";
}
.icon-notifications:before {
  content: "\e94b";
}
.icon-launch:before {
  content: "\e958";
}
.icon-check_circle:before {
  content: "\e90b";
}
.icon-refresh:before {
  content: "\e91c";
}
.icon-arrow_drop_up:before {
  content: "\e954";
}
.icon-arrow_drop_down:before {
  content: "\e955";
}
.icon-preview:before {
  content: "\e94f";
}
.icon-settings:before {
  content: "\e925";
}
.icon-edit-pencil:before {
  content: "\e90c";
}
.icon-user:before {
  content: "\e907";
}
.icon-cog1:before {
  content: "\e90e";
}
.icon-filter1:before {
  content: "\e90f";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-switch:before {
  content: "\e9b6";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-arrow-left1:before {
  content: "\ea39";
}
.icon-arrow-left21:before {
  content: "\ea41";
}
.icon-arrow-up-right2:before {
  content: "\ea3b";
}
.icon-arrow-down-right2:before {
  content: "\ea3d";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-point-down:before {
  content: "\ea05";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-filter:before {
  content: "\ea5b";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-bin2:before {
  content: "\e9ad";
}
.icon-check-circle-o:before {
  content: "\e90a";
}
.icon-ticket:before {
  content: "\f145";
}
.icon-envelope-o:before {
  content: "\f003";
}
.icon-envelope-open-o:before {
  content: "\f2b7";
}
