.grid {
  align-items: center;
  justify-content: center;
  background: white;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  display: grid;
  height: 100vh;
  grid-template-columns: 50% 50%;
  font-family: 'Manrope';
}
.paper {
  width: 40%;
}
input[type="text"],
input[type="password"],
input[type="tel"] {
  text-decoration: none;
  padding: 6px 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #dddddd;
  border-color: rgba(0, 0, 0, 0.23);
  width: calc(100% - 28px);
  height: 28px;
  box-shadow: 0px 3px 3px 0px #0000000d;
}
input[type="tel"] {
  width: 1rem;
  height: 2rem;
}
.back-button {
  position: absolute;
  left: 57px;
  display: flex;
  top: 48px;
  align-items: center;
  cursor: pointer;
}
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin: 0;
  margin-right: 10px;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus {
  border-color: rgba(0, 0, 0, 0.3);
  border-width: 1px;
  outline: none;
  border: 2px solid #465efb;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
}

td {
  padding: 5px;
}
.left {
  display: flex;
  justify-content: center;
  background: #263164;
  height: 100%;
  text-align: center;
  align-items: center;
}

.loginImage {
  margin: auto;
  height: 106px;
  width: 341px;
}
.clientLogo{
  margin: auto;
  width: 341px;
}
.forgot-password {
  text-align: right;
}
.remember-me-box {
  display: flex;
  align-items: center;
}
.right {
  position: relative;
  height: inherit;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo {
  background: #263238;
  border-radius: 10px;
}
.welcome {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin: 10px 0;
}
.greeting-log {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 10px 0 30px;
}
.bottom-bar {
  display: grid;
  grid-template-columns: 50% 50%;
}
.connectel-logo {
  text-align: center;
}

.button-wrapper{
 display: flex;
justify-content:space-between;
}

.button {
  color: #ffffff;
  margin-top: 20px;
  width: 80px;
  border: none;
  cursor: pointer;
  background-color: #465efb;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 0.9rem;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.75;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.button:hover {
  background-color: #161194;
}


.microsoft-signin-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #5E5E5E;
  background-color: #ffffff;
  border: 1px #8C8C8C;
  padding: 12px;
  margin-top: 20px;
}

.disabled {
  background-color: lightgrey;
  pointer-events: none;
}

.microsoft-signin-button:hover {
  cursor: pointer;
  background-color:#f2f2f2;
  /* Slightly darker on hover */
}

/* Microsoft Logo */
.microsoft-logo {
  vertical-align: middle;
  margin-right: 8px;
}

.testImage {
  background-size: 40% 40%;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media (max-width: 1293px) {
  .paper {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .paper {
    width: 80%;
  }
  .loginImage, .clientLogo {
    width: initial;
  }
  .grid {
    display: flex;
    flex-direction: column-reverse;
  }
  .left {
    height: initial;
    width: 100%;
  }
}
