.disabled-sidebar {
  display: none;
}

#side-bar {
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  transition: width 0.5s ease-in-out;
  z-index: 4000;
  height: 100vh;
  background: white;
  overflow-x: auto;
}

#side-bar.active {
  width: 400px !important;
}

#side-bar .header {
  margin: 0;
  padding: 18px 20px 17px !important;
  position: sticky;
  top: 0;
  background: #263164;
  color: white;
  min-height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  font-family: "Montserrat";
  display: flex;
  justify-content: space-between;
}

#side-bar.active .header h2 {
  top: 0px;
}

#side-bar .header h2 {
  position: relative;
  top: -80px;
  transition: top 1s ease-in-out;
  margin: 0;
}

.backdrop {
  position: fixed;
  inset: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.5);
}
.blur {
  background-color: rgba(0, 0, 0, 0.5);
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

#side-bar .sidebar-list-member {
  display: block;
  padding: 12px 20px;
  font-size: 18px;
  color: #263164;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  text-decoration: none;
  transition: background-color 0.5s;
  cursor: pointer;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

#side-bar p {
  font-size: 25px;
  color: white;
  cursor: pointer;
}

#side-bar .header span {
  font-size: 10px;
  padding: 4px;
  background: red;
  font-weight: bold;
  border-radius: 8px;
  position: relative;
  bottom: 3px;
  margin-right: 150px;
  margin-top: 2%;
  height: 15px;
}

#side-bar .sidebar-list-member:hover {
  background-color: lightgray;
}

#side-bar .sidebar-list-member.inactive {
    color: grey;
    pointer-events: none;
  }
  #side-bar .sidebar-list-member.inactive span {
    padding-left: 10px;
    display: inline-block;
    font-size: 12px;
    font-style: italic;
  }