.card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  cursor: pointer;
  max-height: 180px;
  aspect-ratio: 10 / 9;
  margin: 10px;
  width: 211px;
height: 182px;
}
.card-icon-check{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.2rem;
  color: #39C660;
  /* color: #fff; */
  border-radius: 32px;
}

.in-active-card {
  background: #1a2454;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  color: #fff;
  pointer-events: none;
}
.in-active-card .img {
  width: 24px;
  height: 24px;
}
.in-active-card .bottom {
  display: grid;
  width: 100%;
  grid-gap: 2%;
  grid-template-columns: 48% 48%;
  margin-top: 10px;
}
.card:hover {
  transform: scale(0.9);
  }
  .in-active-card:hover {
    transform: none;
    }
.disable {
  pointer-events: none;
  opacity: 0.4;
}
.card-button {
  display: flex;
  padding: 8px 12px;
  gap: 10px;
  background: #34f2e7;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  justify-content: center;
  text-transform: uppercase;
  color: #000533;
  cursor: pointer;
  pointer-events:initial;
}
.card-button:hover {
  transform: scale(0.9);
  }
.cardLeft {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  margin: auto;
}
.img {
  width: 48px;
  height: 48px;
}
.learn-more {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  display: flex;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: initial;
}

.description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 30px;
  line-height: 15px;
  text-align: center;
  margin: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  margin: 5px;
  text-align: center;
}
.box {
  position: relative;
}

@media (max-width: 576px) {
  .card  {
    width: 80%;
  }
}