.Session-timeout-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 25%;
  padding: 30px 10px;
  border-radius: 5px;
  text-align: center;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.Session-timeout-header {
  font-size: 1rem;
  color: black;
  padding: 20px 10px;
}
