body .loading {
  position: fixed;
  color: #ffffff;
  z-index: 3000;
  backdrop-filter: brightness(0.5);
}

.loading {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
}
