.disabled-graph {
  position: relative;;
}

.disable-graph-message {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: -webkit-fill-available;
  opacity: 1;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(26, 36, 84, 0.72) 0%, #263164 100%);
}

.no-graph {
  background: #fff;
  padding: 20px 40px;
  margin: 10px 0px 20px 0px;
}
.no-graph img {
  width: 100%;
}
.title-graph {
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0;
}
.description-graph {
  padding-bottom: 30px;
}

.disable-graph-message .title {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;

  /* White */

  color: #ffffff;
}
.disable-graph-message .description {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}
.second-graph {
  opacity: 0.2;
}
.disable {
  pointer-events: none;
  opacity: 0.4;
}
.relative-position {
  position: relative;
}
.edit-graph{
  position: absolute;
  z-index: 1;
  right: 0;
  cursor: pointer;
  margin: 10px;
}

.config-button {
  display: flex;
  top: 50%;
  left: 50%;
  position: absolute;
  padding: 5px 15px;
  margin: -30px -80px;
  background: #34f2e7;
  opacity: 1;
  z-index: 3000;
  cursor: pointer;
  color: #000533;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
}

.disable-graph-message .button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 10px;
  width: 257px;
  background: #34f2e7;
  border-radius: 25px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000533;
}

.disable-graph-message .button:hover {
  transform: scale(0.9);
}
