.speedo-meter{
  background: -webkit-linear-gradient(top, #4af94a 0%, red 70%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  margin-top: 40px;
  cursor: pointer;
}

.fspopup {
  background-color:#000533b5;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 200px;
  padding-left: 15%;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  z-index: 2;
}

.gauge-scales-popup {
  position: absolute;
  display: inline-block;
  border-radius: 1rem;
  top: 60px;
  bottom: 60px;
  left: 5vw;
  width: 90vw;
  z-index: 2;
  background-color: #ffffff;
}

.gauge-scales-popup-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 0px;
  color: #282828;
  cursor: pointer;
  z-index: 2;
}

.gauge-scale-heading-container{
  position: relative;
}

.gauge-scales-flex-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 25px;
  height: calc(100% - 120px);
  overflow: auto;
}

.gauge-scale-container {
  position: relative;
  margin: 20px;
  background-color: #FFFFFF;
  border-radius:20px;
}

.gauge-scale-metric-title {
  text-align: center;
  padding: 10px;
}

.gauge-scales-stats {
  position: relative;
  top: -65px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
  z-index: 0;
}

.no-data-label-container {
  background-color: #ffffffbf;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  border-radius: 20px;
}

.no-data-label{
  font-size: 20px;
  color: #676464;
  z-index: 2;
  width: 70%;
  margin-left: 15%;
  margin-top: 310px;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
}

.performance-stats {
  border-collapse: collapse;
  width: 80%;
  margin-left: 10%;
  z-index: -1;
  text-align: center;
  top: -75px;
  color: #000533;
  background-color: white;
  border-radius: 5px;
}


.performance-stats tbody tr td:nth-child(1){
  text-align: left;
  padding: 9px;
}

.performance-stats tbody tr td:nth-child(2){
  text-align: right;
  padding: 9px;
}
.popup-tabular-menu {
  display: flex;
  position: absolute;
  right: 230px;
  top: 15px;
  border-radius: 1rem;
  border:1px solid #1A1236;
  padding: 0;
  z-index:1000
}

.popup-tabular-menu li {
  list-style: none;
  padding: 10px;
  margin: 0;
  background-color: white;
  color:black;
  border-radius: 0px;
  cursor: pointer;
  padding-inline: 1rem;
}

.popup-tabular-menu li:hover {
  transition-duration: 1s;
}

.popup-tabular-menu li.active-tab {
  background-color: #1A1236;
  color:white;
}

.gauge-scale-header{
  margin-left: 20px;
  margin-top: 20px;
  font-size: 24px;
  font-family: 'Inter';
}

.info-tooltip {
  z-index: 2;
  opacity: 1;
  font-family: Inter;
  font-size: 13px;
  margin-left: 10%;
  font-weight: normal;
  width: 150px; /* Adjust the width value as desired */
}

.wide {
  width: 60%;
}

.wide-xl {
  width: 80%;
}

.tooltip-center {
  text-align: center;
}

.trends-graph-container {
  position: absolute;
  left: calc(50% - 300px);
  top: calc(50% - 200px);
  width: 600px;
  border: 2px solid lightgrey;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  z-index: 2;
}

.trends-graph-container-footer{
  font-size: 12px;
  font-family: 'Inter';
  color: #727171;
  text-align: center;
}

.button-like {
  border: 1px solid #c9c9c9;
  background-color: #c9c9c9;
  color: #595959;
  width: 50%;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.button-like:hover {
  border: 1px solid blue;
}
.performance-popup-scroll-pointer{
  position: absolute;
  bottom: 20px;
  right: 20px;
  animation: downpointer;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.performance-popup-footer{
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  color: #aeaaaa;
  text-align: right;
  margin-right: 20px;
  padding: 10px;;
}

@keyframes gaugeTitle {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes downpointer {
  0% {
    bottom: 40px;
  }
  50% {
    bottom: 20px;
  }
  100% {
    bottom: 40px;
  }
}

.trendline-button {
  width: 100%;
  border: 2px solid blue;
  border-radius: 1rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
  padding-block: 0.5rem;
  column-gap: 0.8rem;
  margin-top: 1rem;
  position: relative; /* Add 'position: relative' to the container */
}

.performance-result {
  width: 80%;
  padding: 12px 16px;
  opacity: 0;
  text-align: center;
  top: -10px;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0px auto 10px;
  margin-inline: 10%;
  margin-top: 15px;
  animation: showResult 3s 3s forwards;
}

@keyframes showResult {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.periods {
  display: inline-block;
  margin-left: 40rem; /* Add this line to add right margin */
  font-weight: normal;
  font-size: 20px;
}

.refresh-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; /* Adjust margin as needed */
  margin-top: 3px;
}

.filter-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  /* Adjust margin as needed */
  margin-top: 3px;
}

.refresh-text {
  display: inline-block;
  vertical-align: middle;
  margin-top: 3px;
}

.close-button {
  font-size: 16px;
  padding: 6px 20px;
  background-color: #f0f0f0;
  border: 1px solid #1A1236;
  border-radius: 4px;
  cursor: pointer;
  color:#1A1236
}

.close-button:hover {
  background-color: #e0e0e0;
}

.dotted-line {
  height: 1px;
  border-top: 1px dotted #1A1236; /* Adjust the color and style as needed */
}

.benchmark-value-wrapper {
  top: -72px;
  font: Rubik;
  background-color: white;
  width: 100%;
  text-align: center;
  font-size: 35px;
}

.share-button {
  font-size: 16px;
  padding: 6px 24px;
  background-color: #f0f0f0;
  border: 1px solid #1A1236;
  border-radius: 4px;
  cursor: pointer;
  color:#1A1236; 
  position: relative !important;
  left:-50px;
  width:20px;
}
.settings-popup {
   position: absolute;
    top: 4rem;
    /* Adjust the position as needed */
    width: 460px;
    /* Adjust the width as needed */
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    right: 9rem;
    z-index: 1000;
}
.share-popup {
  position: absolute;
  top: 4rem; /* Adjust the position as needed */
  width: 460px; /* Adjust the width as needed */
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  right:9rem;
  z-index: 1000;
}

.share-popup input {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.share-popup input:focus {
  border-color: #007bff;
}
.settings-popup button {
  width: 100%;
  padding: 10px 60px;
  margin-right: 10px;
  border: 1px solid #263164;
  border-radius: 4px;
  background-color: #263164;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  margin-top: 10px;
}
.settings-popup label{
  margin-bottom: 10px;
}
.settings-popup select{
  margin-bottom: 10px;
  padding: 10px;
}

.share-popup button {
  width: 100%;
  padding: 10px 60px;
  margin-right: 10px;
  border: 1px solid #263164;
  border-radius: 4px;
  background-color: #263164;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
}

.share-popup button:last-child {
  margin-right: 0;
}

.share-popup button:hover {
  background-color: white;;
  color: #263164;
}

.share-popup button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
}


.share{
  right:11rem;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-weight: bold;
}

.ownership-footer {
  display: flex;
  align-items: "center";
  position: absolute;
  width: 100%;
  justify-content: center;
  bottom: 20px
}

.ownership-footer-text {
  opacity: 0.5;
  font-size: 12px;
  margin-left: 5px;
}