.link {
  color: blue;
  /* text-decoration: underline; */
  cursor: pointer;
}

.btn {
  color: #ffffff;
  cursor: pointer;
  background-color: #465efb;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 0.9rem;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.75;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.btn:hover {
  background-color: #161194;
}

.clickable {
  cursor: pointer;
}
.evx-widget {
  box-shadow: 0px 10px 13px -6px rgb(0 0 0 / 20%),
    0px 20px 31px 3px rgb(0 0 0 / 14%), 0px 8px 38px 7px rgb(0 0 0 / 12%);
}
